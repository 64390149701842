import axios from 'axios';


export const handleApiCall = async (method = '', url = '', data = {}) => {
	// console.log("process.env.REACT_APP_API====",process.env.REACT_APP_API)
	let axiosConfig = {
		method,
		url: `${process.env.REACT_APP_API}${url}`,
		headers: {
			Authorization: `Bearer ${localStorage?.getItem('sessionToken')}`
		}
	};
	if (method !== 'get') axiosConfig['data'] = data;
	try {
		let response = await axios(axiosConfig);
		return response;
	} catch (error) {
		console.warn(error.response.message);
	}
};
