import React, { useEffect, useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

const Sidebar = ({status}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  let activeStyle = "active";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav w-100">
          <NavLink to="/dashboard" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link w-100">
                  <span className="menu-title w-100">Dashboard</span>{" "}
                  <i className="mdi mdi-speedometer menu-icon" />
                </a>
              </li>
            )}
          </NavLink>

          {/* <li className="nav-item">
      <a className="nav-link">
        <span className="menu-title">Dashboard</span>
        <i className="mdi mdi-speedometer menu-icon" />
      </a>
    </li> */}

          <NavLink to="/user-management" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">User Management</span>{" "}
                  <i className="mdi mdi-account-multiple menu-icon" />
                </a>
              </li>
            )}
          </NavLink>
          <NavLink to="/event-management" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">Event Management</span>{" "}
                  <i className="mdi mdi-calendar-text menu-icon" />
                </a>
              </li>
            )}
          </NavLink>
          {/* <NavLink to="/user-registered-event" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a
                  className="nav-link "
                >
                  <span className="menu-title">User Registered Event</span>{" "}
                  <i className="mdi mdi-calendar-text menu-icon" />
                </a>
              </li>
            )}
          </NavLink> */}
          <NavLink to="/vendor-management" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">Vendor</span>{" "}
                  <i className="mdi mdi-calendar-text menu-icon" />
                </a>
              </li>
            )}
          </NavLink>
          <NavLink
            to="/community-challenge-management"
            style={{ textDecoration: "none" }}
          >
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">
                    Community Challenge Management
                  </span>{" "}
                  <i className="mdi mdi-comment-account menu-icon" />
                </a>
              </li>
            )}
          </NavLink>
          <NavLink to="/workout-management" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link">
                  <span className="menu-title">Workout Management</span>{" "}
                  <i className="mdi mdi-bell-ring menu-icon" />
                </a>
              </li>
            )}
          </NavLink>
          <NavLink
            to="/nutrition-management"
            style={{ textDecoration: "none" }}
          >
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">Nutrition Management</span>{" "}
                  <i className="mdi mdi-receipt menu-icon" />
                </a>
              </li>
            )}
          </NavLink>
          <NavLink
            to="/mental-health-management"
            style={{ textDecoration: "none" }}
          >
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">Mental Health Management</span>{" "}
                  <i className="mdi mdi-yin-yang menu-icon" />
                </a>
              </li>
            )}
          </NavLink>


          
          <NavLink
            to="/what's-my360"
            style={{ textDecoration: "none" }}
          >
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">
                 What's my 360? </span>{" "}  
                  <i className="mdi mdi-television-classic menu-icon" />
                </a>
              </li>
            )}
          </NavLink>
          <NavLink to="/feedbacks" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">Feedbacks</span>{" "}
                  <i className="mdi mdi-comment-account-outline menu-icon" />
                </a>
              </li>
            )}
          </NavLink>
          <NavLink to="/cms-management" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">CMS Management</span>{" "}
                  <i className="mdi mdi-clipboard-text menu-icon" />
                </a>
              </li>
            )}
          </NavLink>
          <NavLink to="/tips" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">Tips Management</span>{" "}
                  <i className="mdi mdi-clipboard-text menu-icon" />
                </a>
              </li>
            )}
          </NavLink>
          <NavLink to="/ramadan" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">Ramadan Articles</span>{" "}
                  <i className="mdi mdi-clipboard-text menu-icon" />
                </a>
              </li>
            )}
          </NavLink>
          <NavLink to="/partner-images" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">Partner Logos</span>{" "}
                  <i className="mdi mdi-clipboard-text menu-icon" />
                </a>
              </li>
            )}
          </NavLink>
          <NavLink to="/recommended-videos" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">Recommended Videos</span>{" "}
                  <i className="mdi mdi-clipboard-text menu-icon" />
                </a>
              </li>
            )}
          </NavLink>
          <NavLink to="/rewards-management" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">Rewards Management</span>{" "}
                  <i className="mdi mdi-clipboard-text menu-icon" />
                </a>
              </li>
            )}
          </NavLink>
          <NavLink to="/fitness-articles" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">Fitness Articles</span>{" "}
                  <i className="mdi mdi-clipboard-text menu-icon" />
                </a>
              </li>
            )}
          </NavLink>
          <NavLink to="/faq" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">FAQs</span>{" "}
                  <i className="mdi mdi-clipboard-text menu-icon" />
                </a>
              </li>
            )}
          </NavLink>
          {/* <NavLink to="/roles-management" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">Roles Management</span>{" "}
                  <i className="mdi mdi-clipboard-text menu-icon" />
                </a>
              </li>
            )}
          </NavLink> */}
          <NavLink to="/reports-management" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">Reports Management</span>{" "}
                  <i className="mdi mdi-clipboard-text menu-icon" />
                </a>
              </li>
            )}
          </NavLink>
          <NavLink to="/badges" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">Badges Management</span>{" "}
                  <i className="mdi mdi-clipboard-text menu-icon" />
                </a>
              </li>
            )}
          </NavLink>
          <NavLink to="/widgets" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">Widgets Management</span>{" "}
                  <i className="mdi mdi-clipboard-text menu-icon" />
                </a>
              </li>
            )}
          </NavLink>
     
              <NavLink to="/license-approval" style={{ textDecoration: "none"}}>
                {({ isActive }) => (
                  <li
                    className={`nav-item ${isActive ? activeStyle : undefined}`}
                  >
                    <a className="nav-link ">
                      <span className="menu-title">Organisation license approval</span>{" "}
                      <i className="mdi mdi-clipboard-text menu-icon" />
                    </a>
                  </li>
                )}
              </NavLink>{" "}
              <NavLink to="/account-information" style={{ textDecoration: "none" }}>
                {({ isActive }) => (
                  <li
                    className={`nav-item ${isActive ? activeStyle : undefined}`}
                  >
                    <a className="nav-link ">
                      <span className="menu-title">Organisational Account information</span>{" "}
                      <i className="mdi mdi-clipboard-text menu-icon" />
                    </a>
                  </li>
                )}
              </NavLink>{" "}
              <NavLink to="/employees-associated" style={{ textDecoration: "none" }}>
                {({ isActive }) => (
                  <li
                    className={`nav-item ${isActive ? activeStyle : undefined}`}
                  >
                    <a className="nav-link ">
                      <span className="menu-title">Employees associated</span>{" "}
                      <i className="mdi mdi-clipboard-text menu-icon" />
                    </a>
                  </li>
                )}
              </NavLink>{" "}
              <NavLink to="/organization-challenges" style={{ textDecoration: "none"}}>
                {({ isActive }) => (
                  <li
                    className={`nav-item ${isActive ? activeStyle : undefined}`}
                  >
                    <a className="nav-link ">
                      <span className="menu-title">Organisation Challenges</span>{" "}
                      <i className="mdi mdi-clipboard-text menu-icon" />
                    </a>
                  </li>
                )}
              </NavLink>{" "}
              <NavLink to="/organisation-dashboard" style={{ textDecoration: "none"}}>
                {({ isActive }) => (
                  <li
                    className={`nav-item ${isActive ? activeStyle : undefined}`}
                  >
                    <a className="nav-link ">
                      <span className="menu-title">Organisational Dashboard</span>{" "}
                      <i className="mdi mdi-clipboard-text menu-icon" />
                    </a>
                  </li>
                )}
              </NavLink>
              <NavLink to="/organisation-cms" style={{ textDecoration: "none" }}>
                {({ isActive }) => (
                  <li
                    className={`nav-item ${isActive ? activeStyle : undefined}`}
                  >
                    <a className="nav-link ">
                      <span className="menu-title">Organisational CMS</span>{" "}
                      <i className="mdi mdi-clipboard-text menu-icon" />
                    </a>
                  </li>
                )}
              </NavLink>
       

          {/* <NavLink to="/organization-portal" style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <li className={`nav-item ${isActive ? activeStyle : undefined}`}>
                <a className="nav-link ">
                  <span className="menu-title">Organization Portal</span>{" "}
                  <i className="mdi mdi-clipboard-text menu-icon" />
                </a>
              </li>
            )}
          </NavLink> */}

          {/* <li className="nav-item">
            <a className="nav-link" href="community-challange-management.php">
              <span className="menu-title">Community Challange Management</span>
              <i className="mdi mdi-comment-account menu-icon" />
            </a>
          </li> */}
          {/* <li className="nav-item">
            <a className="nav-link" href="workout-management.php">
              <span className="menu-title">Workout Management</span>
              <i className="mdi mdi-bell-ring menu-icon" />
            </a>
          </li> */}
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
